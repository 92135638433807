
.error-page {

  width: 100%;
  height: 100%;

  text-align: center;


  padding: 15px;
  .error-message {

    color: red;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .homepage-link {
    font-size: 1.5rem;
  }
}