
.no-available {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;

  .no-available-information{
    font-weight: bold;
    width: 80%;
    color: white;
  }
}