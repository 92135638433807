@import "../../style/colors";

.story-card {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $color-primary;
  padding: 15px;

  .story-title {

    p {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }

  .story-illustration {
    flex: 1;
    overflow: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 15px;
    }

    .featured {
      border: 5px solid ;
      border-color: $color-secondary;
    }
  }

  .story-description {

    height: 20%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 15px;

    .story-text {
      color: black;
      flex: 1;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
    }

    .story-description-separator {
      height: 60%;
      border: 1.5px solid $color-primary;
      background-color: $color-primary;
      margin: auto 0 auto 15px;
    }

    .story-format {
      min-width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 2em;
      }
    }

  }
}