
.player-360-container {

  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: initial;
  padding-right: initial;
  background-color: black;

  height: 100%;

  .row {

    width: 100%;

    // #player-div {

    //   width: 100%;
    //   height: 100%;

    //   canvas {
    //     width: 100% !important;
    //     height: 100% !important;

    //   }
    // }
  }

  // .controls {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}