@import "../../style/colors";
@import "../../style/Variables.scss";

.app-footer {

  background: $background-color;

  height: $footer-height;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;

  border-top: 1px solid #aaa ;

  display: flex;
  justify-content: center;
  margin-top: auto;

}