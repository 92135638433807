@import "../../style/colors";

.information-container {

  padding: 0;
  height: 100%;

  .desktop-content {
    height: 100%;
    margin: 0;

    .video-section {
      height: 100%;
      padding: 15px;
      display: flex;
      justify-content: center;

      video {
        height: 100%;
      }
    }

    .text-section {
      padding: 0;

      .title {
        color: $color-primary;
        padding: 15px;
      }

      p {
        padding-left: 15px;
        padding-right: 15px;
      }

      button {
        color: white;
        display: block;
        margin: auto;
      }
    }

  }

  .mobile-content {
    height: 100%;

    .video-section {
      height: 40%;
      position: sticky;
      top: 0;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    .text-section {
      flex: 1;
      overflow: scroll;
      margin: 0 0 15px;

      .title {
        color: $color-primary;
        padding: 15px;
      }

      p {
        padding-left: 15px;
        padding-right: 15px;
      }

      button {
        color: white;
        display: block;
        margin: auto auto 15px;
      }

    }

  }

}