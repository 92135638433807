@import "../style/colors";

.app-menu {
  display: flex;
  width: 100%;
  height: 100%;

  .logo {
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: space-around;

    p {
      margin-bottom: auto;
      margin-top: auto;
    }

    img {
      height: 70%;
      margin-bottom: auto;
      margin-top: auto;
    }
  }

  .links {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .nav-link {
      margin: auto;
      color: $color-secondary;
      i {
        font-size: 1.3rem;
      }
    }
  }

}