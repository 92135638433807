@import "../../style/colors";
@import "../../style/Variables.scss";

.app-header {

  height: $header-height;
  z-index: 99;

  .navbar {
    background-color: white;
    height: 100%;
    width: 100%;

    .container {
      height: 100%;
      width: 100%;

      .navbar-brand {
        display: block;
        height: 100% !important;

        img {
          height: 100%;
        }
      }

      .navbar-toggler {
        border: initial;

        i {
          font-size: 2rem;
          color: $color-primary;
        }
      }

      .navbar-collapse.collapse {

        .navbar-nav {
          display: flex;
          width: 100%;
          justify-content: space-around;
          a {
            color: $color-secondary;
          }

        }

        &.show {
          .navbar-nav {
            position: absolute;
            top: $header-height;
            left: 0;
            width: 100%;
            background-color: white;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;

            a {
              display: block;
              width: 100%;
              text-align: center;
              color: $color-secondary
            }
          }
        }
      }

    }
  }

}