@import "../../style/colors";


.sequence-listing-page {
  height: 100%;
  padding:0;

  .desktop {
    padding: 0 15px 0 15px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    .fake-header {
      color: $color-primary;
      padding-bottom: 15px;

      h1 {
        width: 100%;
        text-align: center;
      }

      i {
        text-align: center;
        margin: 0 auto;
        font-size: 2.5rem;
      }
    }

    .sequences-section {
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 15px;

      .noSequences {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width:100%;
        font-size:3em;
        p {
          text-align: center;
          padding: 2em;
        }
      }

      .sequence-card {
        margin: 0 0 30px;
        padding: 10px;
        cursor:pointer;
        width: 100%;

        .sequence-illustration {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid $color-primary;
          }
        }

        .sequence-information {

          h2 {
            color: $color-primary;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
          }
        }

      }

    }

  }

  .mobile {
    width: 100%;
    padding: 0 15px 0 15px;
    flex-direction: column;

    .fake-header {
      color: $color-primary;
      padding: 15px;

      h1 {
        width: 100%;
        text-align: center;
      }

      i {
        text-align: center;
        margin: 0 auto;
        font-size: 2.5rem;
      }
    }

    .sequences-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
      margin: 15px;
      cursor:pointer;

      .noSequences {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width:100%;
        font-size:2em;
        p {
          text-align: center;
          padding: 1em;
        }
      }

      .sequence-card {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;

        .sequence-illustration {

          display: flex;
          justify-content: center;
          align-items: center;
          margin: 15px auto 15px;

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid $color-primary;
          }
        }

        .sequence-information {

          h2 {
            color: $color-primary;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
          }
        }

      }


    }

  }

}