@import "colors";
@import "custom_bootstrap";
@import "helper";
@import "animation";

// Make app fit screen size without border
*, *::before, *::after {
  margin: 0;
  padding: 0;
}

.App {
  //text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
