
.card-shadow {
  box-shadow:  0 10px 15px #b1b1b1,
  0px -2px 5px #b1b1b1;
  background-color: white;
  border-radius: 15px;
}

.truncate-ellipsis-1 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.truncate-ellipsis-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.truncate-ellipsis-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}