
.m360-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    width: 20%;
    height: auto;
    animation:spin 0.8s ease infinite;
  }
}