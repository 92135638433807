@import "../../style/colors";

.login {

  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .login-input {
    width: 100%;
    justify-content: center;

    .input-field {

      input {
        border-radius: 1.35rem;
        text-align: center;
        border: 1px solid $color-secondary;
      }

      .error-feedback::placeholder {
        color: red;
        font-weight: bold;
      }
    }

    .validate-button {

      button {
        width: 75px;
        color: white;
      }
    }
  }

  .credits-section {
    position: fixed;
    bottom: 0;
  }
}