@import "../../style/colors";

.stories-wall {

  height: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wall-content {

    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    .tablet-story-card {
      min-height: 20em;
      max-height: 40%;
      margin: 0;
      padding: 10px;
      cursor:pointer;

      .story-card {

        .story-title {
          p {
            font-size: 1rem;
            -webkit-line-clamp: 2;
          }
        }

        .story-description {
          display: none;
        }
      }

    }
  }

  .navigation-section {
    margin: 0;
    justify-content: space-around;
    width: 100%;
    padding: 15px;

    button {
      color: white;
    }
  }
}