@import "../../style/colors";

.desktop-magic-box-card {
  margin: 0 0 15px;
  justify-content: space-between;
  padding: 15px;

  .card-illustration {
    padding: 0;
    margin: 0 auto;

    img {
      border: 2px solid $color-secondary;
      border-radius: 50%;
      padding: 2px;
      width: 100%;
    }
  }

  .card-text {

    h2 {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    p {
      color: grey;
      font-weight: bold;
      margin: auto;
    }
  }
}

.mobile-magic-box-card {
  margin: 0 0 15px;
  justify-content: space-between;
  padding: 15px;

  .card-illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 auto;

    img {
      border: 2px solid $color-secondary;
      border-radius: 50%;
      padding: 2px;
      width: 100%;
    }
  }

  .card-text {
    margin-top: 15px;

    h2 {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    p {
      color: grey;
      font-weight: bold;
      margin: auto;
      font-size: 0.8rem;
    }
  }
}