@import "../../style/colors";

.search-container {

  height: 100%;
  padding: 0;

  .mobile-content {

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-section {
      flex: 1;

      .fake-header {
        color: $color-primary;
        font-size: 2.5rem;
        text-align: center;
        flex-direction: column;
        padding-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
      }

      .search-section {

        margin-top: 30px;
        padding: 15px;

        input {
          border-radius: 1.35rem;
          text-align: center;
          border: 1px solid $color-secondary;
          margin-bottom: 15px;
        }

        button {
          width: 100%;
          color: white;
        }

      }

      .no-story {
        padding: 20px;
      }

    }

    .result-content {

      height: 100%;
      margin: 0;
      overflow: auto;

      .story-card-wrapper {
        height: 100%;
        overflow: auto;
        padding: 15px;
        max-height: 90%;
      }

    }

    .result-title {
      color: grey;
      font-size: 1.5rem;
      font-weight: bold;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
    }

    .stories-carousel {
      margin-bottom: 15px;
    }

  }

}