@import "../../style/colors";

.expanded-story {

  height: 100%;
  padding: 0;

  .actions-section {
    justify-content: space-between;
    padding: 15px;
    margin: 0 0 0;

    .like, .dislike, .share, .navButton {
      flex: 1;
      text-align: center;
      cursor: pointer;
      color: $color-secondary;
    }

    .navButton {
      border: #5B5B5B;
      i {
        border: 2px solid #5B5B5B;
        color: #5B5B5B;
      }
    }

    i {
      text-align: center;
      color: $color-secondary;
      font-size: 1.4rem;
      border: 2px solid $color-secondary;
      padding: 15px;
      border-radius: 50%;
    }

  }

  .desktop-content {
    position: relative;
    height: 100%;

    .story-content {
      height:100%;
      margin: 0;

      .expanded-story-illustration {
        display: flex;
        justify-content: center;
        align-items: center;
        height:100%;
        padding: 0;

        .video-360-format {
          text-align: center;
          i, span {
            color: white ;
            opacity: 0.8;

            &:hover, &:active{
              -webkit-text-stroke: 1px black;
            }
          }
          i {
            font-size: 4rem;
          }
        }

        .video-format {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 15px;

          video {
            width: 100%;
          }
        }

        .podcast-format {
          width: 100%;
          padding: 15px;

          audio {
            width: 100%;
          }
        }

        .image-format {
          height: 100%;
          width: 100%;
          padding: 15px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .expanded-story-text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;

        .return-section {
          padding-right: 15px;
          height: 40px;
          text-align: right;

          i {
            font-size: 2rem;
            cursor: pointer;
          }
        }

        .story-title {
          color: $color-primary;
          width:100%;
        }

        .green-fact-item {
          margin-top: 15px;
        }

        .story-row-external-link {
          height: 100%;
          margin: 15px auto;
        }

        .story-external-link {

          margin: auto;
          display: block;

          a {
            color: white;
          }
        }
      }
    }




  }

  .mobile-content {
    height: 100%;

    .return-section {
      position: sticky;
      top: 0;
      padding-right: 15px;
      height: 40px;
      text-align: right;
      background-color: white;

      i {
        font-size: 2rem;
        cursor: pointer;
      }
    }

    .expanded-story-illustration {
      position: sticky;
      top: 40px;
      height: 40%;
      margin: 0;
      z-index: 99;

      justify-content: center;
      align-items: center;

      .video-360-format {
        text-align: center;

        i, span {
          color: white ;
          opacity: 0.8;

          &:hover, &:active{
            -webkit-text-stroke: 1px black;
          }
        }

        i {
          font-size: 4rem;
        }
      }

      .video-format {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        video {
          width: 100%;
          object-fit: cover;
        }
      }

      .podcast-format {
        width: 100%;
        padding: 15px;

        audio {
          width: 100%;
        }
      }

      .image-format {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

    }

    .expanded-story-text {
      padding: 15px;
      margin: 0;

      .story-title {
        color: $color-primary;
        width:100%;
      }
    }

    .story-row-external-link {
      margin: auto;
    }

    .green-fact-item {
      margin: 0 15px 15px 15px;
    }

    .story-external-link {

      margin: auto;
      width: 80%;
      display: block;

      a {
        color: white;
      }
    }
  }
}

@media all and (display-mode: fullscreen) {
  video {
    object-fit: scale-down !important;
  }
}
