@import "../../style/colors";
@import "../../style/Variables.scss";

.catalog-container {

  height: 100%;
  width:100%;
  padding:0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  .desktop {
    height: 100%;
  }

  // Mobile CSS
  .mobile {
    width:100%;
    height: 100%;
    margin:0;

    .swipeable-stories {
      width: 100%;
      margin: 0;
      padding: 15px;
    }
  }

 }
