@import "../../style/colors";

.magic-box-container {

  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .fake-header {
    color: $color-primary;
    font-size: 2.5rem;
    text-align: center;
    flex-direction: column;
    padding-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  .magic-box-description {
    color: grey;
    font-weight: bold;
    padding: 15px;
  }

  .magic-box-listing {

    flex: 1;
    padding: 15px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .external-link {

      &:hover {
        text-decoration: none;
      }
    }

  }

}