@import "../../style/colors";

.sequence-stories-page {

  height: 100%;
  padding: 0;

  .desktop {
    height: 100%;
    margin: 0;
    flex-direction: column;

    .fake-header {
      margin-left: 15px;
      margin-right: 15px;

      i {
        color: $color-primary;
        font-size: 2rem;
      }

      h1 {
        width: 100%;
        font-family: 'Karla Bold', serif;
        text-align: center;
        color: $color-primary;
        margin: 0;

        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
    }

    .stories-wall {
      overflow: auto;
    }

  }

  .mobile {
    height: 100%;
    flex-direction: column;


    .fake-header {
      display: flex;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      flex-wrap: nowrap;

      i {
        color: $color-primary;
        font-size: 2rem;
      }

      h3 {
        color: $color-primary;
        margin: 0 auto auto 15px;
        font-family: 'Karla Bold', serif;

        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sequence-stories-listing {
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px;
      overflow: auto;

      .story-card {
        height: 90%;
        margin-bottom: 15px;
      }
    }
  }
}