
$leafColor: #208d21;
$leafBorderColor: #208d21;

.green-fact-item {
  border: 3px solid $leafColor;
  border-radius: 5px;

  .title {
    background-color: white;
    color: $leafBorderColor;
    display: inline-block;
    font-size: 1.2rem;
    position: relative;
    top: -1.3rem;
    left: 15px;
    padding: 4px;
    margin: 0;

    i {
      transform: rotate(30deg);
      padding: 5px;
    }
  }

  .html-content {
    margin-top: -1.2rem;
    padding: 0 15px;
    max-height: 75px;
    overflow: hidden;
    position: relative;

    :after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset white 0 -10px 10px;
    }

    &.green-fact-opening {
      animation: 1.5s green-fact-opening ease-in-out both;
    }
  }

  .show-more {
    text-decoration: underline;
    padding-left: 15px;

    cursor: pointer;
    color: blue;
  }

}

@keyframes green-fact-opening {

  to {
    max-height: 1000px;
  }
}
